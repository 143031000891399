<template>
  <v-list dense>
    <Heading>TAGS</Heading>
    <!-- loading UI -->
    <v-skeleton-loader
      v-bind="attrs"
      type="list-item-avatar"
      v-if="isLoading"
    ></v-skeleton-loader>
    <!-- tags list -->
    <v-list-item-group multiple v-else
                       color="primary" 
                       v-model="selectedTags">
      <v-list-item v-for="tag in tags" 
                   :key="tag.id"
                   :value="tag.id">
        <v-list-item-title>
          {{ tag.name | uppercase }}
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
    <!-- back button -->
    <Back @click="back"/>
  </v-list>
</template>

<script>
  import _ from 'lodash'
  import API from '@/api'
  import submenuMixin from '@/mixins/book-context-submenu'

  import Back from '../Back'
  import Heading from '../Heading'

  export default {
    name: 'BookContextMenuTags',

    mixins: [ submenuMixin ],

    components: {
      Back,
      Heading
    },

    data: () => ({
      tags: [],
      selectedTags: null
    }),

    mounted()
    {
      this.initSelectedTags()

      this.fetchTags()
    },

    computed: {},

    methods: {
      fetchTags()
      {
        this.isLoading = true

        API.tags.list()
                .then((res) => {
                  
                  this.tags = res.data.data

                  this.isLoading = false

                })
      },
      initSelectedTags()
      {
        if( this.book )
          this.selectedTags = _.map(this.book.tags, 'id')
      }
    },

    watch:
    {
      selectedTags(tags, prev)
      {
        // do not sync tags on first load
        if(!_.isNull(prev))
        {
          
          this.isLoading = true

          API.tags.sync(tags, 'book', this.book.id )
                  .then(()=>{
                    
                    this.$store.commit('growl', 'Tags updated')

                    this.isLoading = false
                  
                  })
        }
      }
    }

  }
</script>
