<template>
  <v-list-item @click="onClick" class="grey lighten-3 text-center">
    <v-list-item-title>
      <v-icon>mdi-arrow-left</v-icon>
      BACK
    </v-list-item-title>
  </v-list-item>
</template>


<script>
  export default {
    name: 'BookContextMenuBack',

    components: {},

    props: {},

    data: () => ({}),

    mounted(){},

    computed: {},

    methods: {
      onClick(e)
      {
        this.$emit('click', e)
      }
    },

    watch: {}

  }
</script>
