<template>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      
      <BookScannerLoading v-if="loading" />

      <BookScannerPreview 
        v-else-if="book" 
        :book="book" 
        @save="onSave"
        @goToScan="reset"/>

      <BookScannerSearch 
        v-else-if="searchMode" 
        @selected="onSearch"
        @goToScan="reset"
        @close="dialog = false"
        :hide-message="searchMode"/>

      <v-card v-else>
        <v-card-title
          class="headline red darken-2 white--text"
          primary-title
        >
          Scan book barcode
        </v-card-title>

        <div class="py-3">
          <v-quagga :onDetected="onDetected" 
                    :readerSize="readerSize" 
                    :readerTypes="['ean_reader']"
                    v-if="dialog"></v-quagga>
        </div>
        
        <v-divider></v-divider>

        <v-card-actions class="grey">
          <v-btn text dark @click="dialog = false">
            <v-icon class="mr-1">mdi-close</v-icon>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>

      <Loading :status="overlay"/>

    </v-dialog>
</template>

<script>
  import _ from 'lodash'
  import Api from '../../api'
  
  import BookScannerLoading from '../scanner/Loading'
  import BookScannerPreview from '../scanner/Preview'
  import BookScannerSearch from '../scanner/Search'

  import Loading from '../Loading'

  export default {
    name: 'BookScanner',

    components: {
        BookScannerLoading,
        BookScannerPreview,
        BookScannerSearch,
        Loading
    },

    props: {

    },

    data: () => ({
      dialog: false,
      barcode: null,
      book: null,
      loading: false,
      overlay: false,
      readerSize: {
        width: 640,
        height: 480
      },
      mode: 'scanner',
      detecteds: []
    }),

    computed: {
      hasBarcode()
      {
        let hasBarcode = !_.isNull(this.barcode)

        return hasBarcode
      },
      scannerMode()
      {
        return this._scannerMode === 'scanner'
      },
      searchMode()
      {
        return this._scannerMode === 'search'
      },
      _scannerMode()
      {
        return this.mode ? this.mode.toLowerCase() : null
      }
    },

    mounted() {

    },

    methods: {
      async lookupIsbn(isbn){

        this.loading = true
        
        try {
          await Api.lookupIsbn(isbn).then((res)=>{
            this.book = res.data.data
            this.loading = false
          })  
        } catch(e) {
          // no ISBN match.
          this.mode = 'search'
          this.loading = false
        }
      },
      onDetected (data) {
        let barcode = data.codeResult.code
        this.barcode = barcode
      },
      onSave(isbn) {
        this.overlay = true
        Api.importIsbn(isbn).then((res)=>{
          this.dialog = false
          this.overlay = false
          this.book = null
          // redirect to new book
          var bookId = res.data.data.id
          this.$router.push({ name: 'book', params: {bookId} })
        })
        .catch((e)=>{
          this.overlay = false
          this.$store.commit('growl', {
            color: 'error',
            text: 'An error occurred saving this book.', // e.response.data.message
          }) 
          console.error(e.response)
        })
      },
      onSearch(book) {
        this.book = book
      },
      reset()
      {
        this.barcode = null
        this.book = null
        this.mode = 'scanner'
      },
      show(mode)
      {
        this.dialog = true
        
        if(mode)
          this.mode = mode
      }
    },

    watch: {
      barcode(code){
        if(code)
           this.lookupIsbn(code)
      }
    }

  }
</script>

<style>
    #interactive.scanner {
        width: 100%;
        height: 350px;
    }
    #interactive.scanner video {
        width: 100%;
        height: 100%;
    }
    #interactive.scanner canvas {
        display: none;
    }
</style>