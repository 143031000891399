<template>
  <div class="hidden-print-only">
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
      >
      <Logo class="my-3 hidden-lg-and-up pointer"
            @click="goTo('/')"/>
      
      <v-card tile color="red lighten-4">
        <v-card-title>
          {{ user.name }}
        </v-card-title>
        <v-card-subtitle>
          {{ user.email }}
        </v-card-subtitle>
      </v-card>

      <v-list dense shaped>

        <div v-for="(item,i) in items"
                  :key="i">

          <v-list-group v-if="item.submenu" 
                        :value="false">
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="(sub, j) in item.submenu"
              :key="j"
              @click="goTo(sub.route)"
              :class="sub.route.name === $route.name ? 'v-item--active v-list-item--active' : ''">
              <v-list-item-action class="pl-2">
                <v-icon>{{ sub.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ sub.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link 
            :key="item.text"
            @click="goTo(item.route)"
            :class="item.route.name === $route.name ? 'v-item--active v-list-item--active' : ''"
            v-else>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
          </v-list-item>

        </div>
      </v-list>

      <v-footer absolute class="text-center">
        Booklist {{ $store.getters.appVersion }}
      </v-footer>
    </v-navigation-drawer>

    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app color="red"
      >
      
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4 hidden-md-and-down"
      >
        <Logo white class="pointer"
              @click="goTo('/')"/>
      </v-toolbar-title>
      
      <Search class="pt-2"/>
      
      <v-spacer></v-spacer>

      <portal-target name="topbarActions"></portal-target>

      <v-btn fab dark small @click="logout">
        <v-icon>mdi-power</v-icon>
      </v-btn>

    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Logo from './Logo';
  import Search from './Search'

  export default {
    name: 'Topbar',

    components: {
      Logo,
      Search
    },

    mounted(){},

    computed: {
        ...mapGetters([
          'user'
        ])
    },

    data: () => ({
      dialog: false,
      drawer: null,
      items: [
        { icon: 'mdi-book', text: 'BOOKS', route:{ name:'books' } },
        {
          icon: 'mdi-more',
          text: 'OTHER',
          submenu: [
            { icon: 'mdi-newspaper-variant-outline', text: 'MAGAZINES', route:{ name:'magazines' } },
            { icon: 'mdi-cursor-default-click', text: 'WEBSITES', route:{ name:'websites' } },
            { icon: 'mdi-email', text: 'NEWSLETTERS', route:{ name:'newsletters' } },
            { icon: 'mdi-book-multiple', text: 'BOOKSHELVES', route:{ name:'bookshelves' } },
            { icon: 'mdi-account-circle', text: 'AUTHORS', route:{ name:'authors' } },
            { icon: 'mdi-asterisk', text: 'GENRES', route:{ name:'genres' } },
            { icon: 'mdi-label', text: 'TAGS', route:{ name:'tags' } },
            { icon: 'mdi-brain', text: 'TOPICS', route:{ name:'topics' } },
          ]
        },
        { icon: 'mdi-star', text: 'FAVOURITES', route:{ name:'favourites' } },
        {
          icon: 'mdi-wrench',
          text: 'UTILITY',
          submenu: [
            { icon: 'mdi-graph', text: 'NETWORK', route:{ name:'network' } },
            { icon: 'mdi-help-circle', text: 'RANDOM QUOTE', route:{ name:'random-quote' } },
            { icon: 'mdi-brain', text: 'AI WRITER', route:{ name:'writer' } },
            { icon: 'mdi-chat', text: 'CHATBOT', route:{ name:'chatbot' } },
          ]
        },
        { icon: 'mdi-magnify', text: 'SEARCH', route:{ name:'search' } },
        { icon: 'mdi-cog', text: 'SETTINGS', route:{ name:'settings' } },
      ]
    }),

    methods: {
      goTo(route)
      {
        if(route.name != this.$router.currentRoute.name) 
          this.$router.push(route)
      },
      logout()
      {
        this.$store.dispatch('logout')
      },
      showMenu()
      {
        this.drawer = true
      }
    },

    watch: {
      drawer(status)
      {
        this.$emit('toggle', status)
      }
    }

  }
</script>
