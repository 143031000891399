<template>
  <v-list dense>
    <Heading>
      {{ book.title | uppercase }}
    </Heading>
    <v-list-item-group color="primary">
      <!-- Favourite toggle -->
      <Favourite :book="book"/>
      <!-- Tags -->
      <v-list-item @click="openSubmenu('Tags')">
        <v-list-item-icon>
          <v-icon>mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            TAGS
          </v-list-item-title>
        </v-list-item-content>       
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>  
      </v-list-item>
      <!-- Bookshelves -->
      <v-list-item @click="openSubmenu('Bookshelves')">
        <v-list-item-icon>
          <v-icon>mdi-book-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            BOOKSHELVES
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>       
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  import Favourite from './submenu/Favourite'
  import Heading from './Heading'

  export default {
    name: 'BookContextMenuMainList',

    components: {
      Favourite,
      Heading
    },

    props: {
      book: {
        type: Object
      }
    },

    data: () => ({
      
    }),

    mounted(){},

    computed: {},

    methods: {
      openSubmenu(submenu)
      {
        this.$emit('submenu', submenu)
      }
    }

  }
</script>
