<template>

  <v-dialog
      v-model="dialog"
      width="500"
    >
    <template v-slot:activator="{ on }" v-if="button">
      <v-btn
        :color="color"
        dark
        v-on="on"
      >
        {{ label }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="bookshelfId">Edit bookshelf</span>
        <span v-else>New bookshelf</span>
      </v-card-title>

      <v-card-text>

        <v-form>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model="bookshelf.name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="bookshelf.id" class="text-center">

                <div v-if="toggleDelete">
                  <p class="red--text">Are you sure you want to permanent delete this bookshelf?</p>
                  <v-btn class="ma-2"
                    @click="toggleDelete=!toggleDelete"
                  >
                    Undo
                  </v-btn>
                  <v-btn
                    color="error" class="ma-2"
                    @click="deleteBookshelf"
                  >
                    Yes, delete!
                  </v-btn>
                </div>
                
                <div v-else>
                  <v-btn
                    color="error"
                    @click="toggleDelete=!toggleDelete"
                    block
                  >
                    Delete bookshelf
                  </v-btn>
                </div>

              </v-col>

            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions color="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  export default {
    name: 'BookshelfModal',

    components: {},

    props: {
      bookshelfId: {
        type: Number
      },
      button: {
        type: Boolean,
        default: true
      },
      color: {
        type: String,
        default: ()=> 'secondary'
      },
      label: {
        type: String,
        default: ()=> 'New'
      }
    },

    data: () => ({
      bookshelf: {
        name: ''
      },
      dialog: false,
      overlay: false,
      toggleDelete: false
    }),

    mounted(){
      if( this.bookshelfId )
        this.fetchBookshelf()
    },

    methods: {
      deleteBookshelf()
      {
        if(this.bookshelfId){
          
          this.overlay = true
          
          Api.bookshelves.delete(this.bookshelfId).then(()=>{
            
            this.$emit('delete', this.bookshelf)

            this.bookshelf = null
            this.overlay = false
            this.dialog = false
            
            this.$store.commit('growl', {
              color: 'success',
              text: 'Bookshelf deleted.'
            })

          })

        }
      },
      fetchBookshelf()
      {
        if(this.bookshelfId)
          Api.bookshelves.one(this.bookshelfId).then((res)=>{
            this.bookshelf = res.data.data
          })
      },
      save()
      {
        let rest = this.bookshelfId ? 
                    Api.bookshelves.update(this.bookshelfId, this.bookshelf) : 
                    Api.bookshelves.create(this.bookshelf)
        
        this.overlay = true
        
        rest.then(()=>{
          
          if(!this.bookshelfId)
            this.bookshelf = { name:'' }
          
          this.overlay = false
          this.dialog = false

          this.$emit('save', this.bookshelf)

        })

      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      bookshelfId()
      {
        this.fetchBookshelf()
      }
    },

  }
</script>
