<template>
  <v-card
    class="mx-auto"
    max-width="344" 
    @click="onClick"
    elevation="24"
    style="overflow:hidden"
    @contextmenu="onContextMenu"
  >
    <v-row :class="ribbonColor"
           style="height:5px"></v-row>

    <v-img
      :src="item.cover_url"
      :height=" height + 'px'" 
      v-if="item.cover_url"
    ></v-img>

    <div v-else>

      <v-card-title>
        {{ item.title }}
      </v-card-title>

      <v-card-subtitle>
        <v-chip class="my-1" small
          v-for="author in item.authors" :key="author.id">
          {{ author.fullname }}
        </v-chip>
      </v-card-subtitle>

    </div>

    <v-row justify="center" class="grey lighten-3">
      <v-rating
        color="warning"
        size="15"
        readonly
        :value="item.rating"
      ></v-rating>
    </v-row>

    <v-card-actions>
      
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon class="mr-1">mdi-bookmark</v-icon> 
        {{ item.quotes_count }}
      </v-btn>

      <v-btn icon v-if="item.bookshelves">
        <v-icon class="mr-1">mdi-book-multiple</v-icon> 
        {{ item.bookshelves.data.length }}
      </v-btn>

      <v-btn icon v-if="item.tags">
        <v-icon class="mr-1">mdi-label</v-icon> 
        {{ item.tags.length }}
      </v-btn>

      <v-spacer></v-spacer>

    </v-card-actions>

  </v-card>
</template>

<script>
  export default {
    name: 'BookCard',

    components: {},

    props: {
      book: {
        type: Object
      },
      height: {
        type: Number,
        default: 250
      }
    },

    data: () => ({
      item: {
        authors: []
      }
    }),

    mounted(){
      if(this.book)
        this.item = this.book
    },

    computed: {
      ribbonColor()
      {
        if( this.book.read_at )
          return 'success'
        else if( this.book.started_at )
          return 'primary'
        return 'grey darken-2'
      }
    },

    methods: {
      onClick()
      {
        this.$emit('click', this.book)
      },
      onContextMenu(e)
      {
        this.$emit('contextmenu', e, this.book)
      }
    }

  }
</script>
