<template>
  <div>
    <div v-if="bookshelves.length">
      <span class="text-h5">Bookshelves</span>
    </div>
    <v-chip
      class="ma-2"
      :color="isActive(bookshelf)"
      v-for="bookshelf in bookshelves" 
      :key="bookshelf.id"
      @click="toggleBookshelf(bookshelf)"
    >
      <v-progress-circular
                :size="20"
                :width="3"
                indeterminate
                v-if="bookshelf.id===isLoading"
              ></v-progress-circular>
      <span v-else>{{ bookshelf.name }}</span>
    </v-chip>
  </div>
</template>

<style type="text/css">
  
</style>

<script>

  import _ from 'lodash'
  import Api from '../../api'

  export default {
    name: 'Bookshelves',

    components: {},

    props: {
      book: {
        type: Object,
        default: ()=>{
          return {
            bookshelves: { data:[] }
          }
        }
      }
    },

    data: () => ({
      bookshelves: [],
      isLoading: null
    }),

    mounted(){
      this.loadBookshelves()
    },

    methods: {
      isActive(bookshelf)
      {
        if(!this.book.bookshelves)
          return ''

        let isActive = _.find(this.book.bookshelves.data, {id:bookshelf.id}),
            label = isActive ? 'primary' : ''

        return label
      },
      loadBookshelves()
      {
        Api.bookshelves.list().then((res)=>{
          // res.data.links
          this.bookshelves = res.data.data
        })
      },
      toggleBookshelf(bookshelf)
      {
        if( !this.book.id )
          return;

        let bookId = this.book.id

        this.isLoading = bookshelf.id

        Api.bookshelves.toggle(bookshelf.id,bookId).then((res)=>{
          this.$emit('toggle', bookshelf, res.data.new_status)
          this.isLoading = null
        })
      }
    }

  }
</script>
