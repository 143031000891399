<template>
  <div>

    <!-- HEADER. -->
    <Header v-if="book.title" color="red lighten-4">

      <!-- Book authors. -->
      <template v-slot:sup>
        <v-container fluid ma-0 pb-0>
          <v-chip
            class="ma-2"
            color="primary"
            label
            v-for="(author,i) in book.authors" 
            :key="`author-${i}`"
            @click="goToAuthor(author)"
          >
            <v-icon left>mdi-account-circle-outline</v-icon>
            {{ author.fullname }}
          </v-chip>
        </v-container>
      </template>

      <!-- Book title. -->
      <div style="word-break: break-word;">
        <FavouriteButton :status="isFav"
                         model-type="book" 
                         :model-id="book.id" 
                         @toggled="onFavToggle"
                         v-if="book.id"/>
        {{ book.title }}
      </div>

    </Header>
    <!-- END: HEADER -->

    <v-row align="start" v-if="bookIsLoaded" class="mt-2 px-2">

        <!-- Book cover. -->
        <v-col cols="12" md="4" 
               v-if="book.cover_url"
               class="hidden-print-only">
          <v-img :src="book.cover_url" 
                 class="book-cover"
                 @load="onCoverLoad"
                 :style="{ visibility: isCoverLoaded ? 'visible':'hidden' }"></v-img>
          <v-skeleton-loader type="image"
                 v-if="!isCoverLoaded"/>
        </v-col>

        <!-- Book details. -->
        <v-col cols="12" :md="book.cover_url ? 8 : 12">

          <div v-if="book.description"
               v-html="book.description"></div>

          <v-simple-table class="my-4">
            <template v-slot:default>
              <tbody>
                <tr v-if="book.isbn">
                  <th>ISBN</th>
                  <td>{{ book.isbn }}</td>
                </tr>
                <tr v-if="book.year">
                  <th>Year</th>
                  <td>{{ book.year }}</td>
                </tr>
                <tr v-if="book.series">
                  <th>Series</th>
                  <td>{{ book.series.title }}</td>
                </tr>
                <tr v-if="book.created_at">
                  <th>Creation date</th>
                  <td>{{ formatDate(book.created_at) }}</td>
                </tr>
                <tr v-if="!hideStart">
                  <th>Reading start date</th>
                  <td>
                    <book-datetime label="START" 
                                   icon="mdi-play"
                                   :value="book.started_at"
                                   @change="updateDatetimeStart"/>
                  </td>
                </tr>
                <tr v-if="!hideEnd">
                  <th>Reading end date</th>
                  <td>
                    <book-datetime label="STOP" 
                                   icon="mdi-stop"
                                   :value="book.read_at"
                                   @change="updateDatetimeRead"/>
                  </td>
                </tr>
                <tr v-if="book.position">
                  <th>Position</th>
                  <td>{{ book.position }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <slot></slot>

        </v-col>
      </v-row>
    <v-row v-else>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="card-avatar"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="8">
        <v-skeleton-loader type="article,article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<style type="text/css">
  @media (max-width: 767px) {
    .book-cover .v-responsive__sizer {
      padding-bottom: 30vh !important;
      height: 400px;
    }
    .book-cover .v-image__image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
</style>

<script>
  
  import _ from 'lodash'
  import Api from '../../api'
  import Utils from '../../utils'

  import BookDatetime from './Datetime'
  import FavouriteButton from '../FavouriteButton'
  import Header from '@/components/Header'

  export default {
    name: 'BookRow',

    mixins: [
      Utils
    ],

    components: {
      BookDatetime,
      FavouriteButton,
      Header
    },

    props: {
      book: {
        type: Object,
        default: ()=>{
          return {
            authors: []
          }
        }
      },
      height: {
        type: Number,
        default: 350
      },
      hideStart: {
        type: Boolean,
        default: false
      },
      hideEnd: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      isCoverLoaded: false,
      isFav: false
    }),

    mounted() {
      this.loadFavourites()
    },

    computed: {
      bookIsLoaded()
      {
        return (this.book.isbn||this.book.id)
      }
    },

    methods: {
      goToAuthor(author)
      {
        this.$router.push({ name: 'author', params: { authorId: author.id } })
      },
      loadFavourites()
      {
        Api.favourites.list()
           .then((res)=>{
            var book = _.chain(res.data.books)
                        .filter({ id:this.book.id })
                        .value()
              this.isFav = !_.isEmpty(book)
           })
      },
      onCoverLoad()
      {
        this.isCoverLoaded = true
      },
      onFavToggle()
      {
        this.loadFavourites()
      },
      updateDatetimeRead(newValue)
      {
        Api.books.update(this.book.id, { read_at:newValue })
                 .then(()=>{
                  this.$store.commit('growl', {
                    color: 'success',
                    text: 'Reading end date updated.'
                  })
                 })
      },
      updateDatetimeStart(newValue)
      {
        Api.books.update(this.book.id, { started_at:newValue })
                 .then(()=>{
                  this.$store.commit('growl', {
                    color: 'success',
                    text: 'Reading start date updated.'
                  })
                 })
      }
    },

    watch: {}

  }
</script>
