<template>

  <v-dialog
      v-model="dialog"
      width="500"
    >
    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        Clear Favourites
      </v-card-title>

      <v-card-text>

        <p class="text-center text-h5 pt-5">
          Do you want to clear all {{ modelType }} favourites?
        </p>

        <p class="text-center red--text text-h5">
          ⚠️ This action cannot be undone.
        </p>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Yes, Clear all
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import API from '../../api'

  //import _ from 'lodash'
  
  export default {
    name: 'FavouriteClearAllModal',

    mixins: [],

    components: {},

    props: {
      modelType: {
        type: String,
        default: ()=> ''
      }
    },

    data: () => ({
      dialog: false,
      overlay: false
    }),

    mounted()
    {
      
    },

    methods: {
      save()
      {
        this.overlay = true

        API.favourites.clearAll(this.modelType)
                      .then(()=>{

                        this.overlay = false

                        this.dialog = false

                        this.$store.commit('growl', 'Favourite cleared')

                        this.$emit('clear')

                      })
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      
    },

  }
</script>
