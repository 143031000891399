<template>
  <v-list dense>
    <Heading>BOOKSHELVES</Heading>
    <!-- loading UI -->
    <v-skeleton-loader
      type="list-item-avatar"
      v-if="isLoading"
    ></v-skeleton-loader>
    <!-- tags list -->
    <v-list-item-group multiple v-else
                       color="primary" 
                       v-model="selectedBookshelves">
      <v-list-item v-for="bookshelf in bookshelves" 
                   :key="bookshelf.id"
                   :value="bookshelf.id">
        <v-list-item-title>
          {{ bookshelf.name | uppercase }}
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
    <!-- back button -->
    <Back @click="back"/>
  </v-list>
</template>

<script>
  import _ from 'lodash'
  import API from '@/api'
  import submenuMixin from '@/mixins/book-context-submenu'

  import Back from '../Back'
  import Heading from '../Heading'

  export default {
    name: 'BookContextMenuBookshelves',

    mixins: [ submenuMixin ],

    components: {
      Back,
      Heading
    },

    data: () => ({
      bookshelves: [],
      selectedBookshelves: null
    }),

    mounted()
    {
      this.initSelectedBookshelves()

      this.fetchBookshelves()
    },

    computed: {},

    methods: {
      fetchBookshelves()
      {
        this.isLoading = true

        API.bookshelves.list()
                       .then((res) => {
                          
                          this.bookshelves = res.data.data

                          this.isLoading = false

                       })
      },
      initSelectedBookshelves()
      {
        if( this.book && this.book.bookshelves )
        {
          let bookshelves = this.book.bookshelves.data
          
          this.selectedBookshelves = _.map(bookshelves, 'id')
        }
      }
    },

    watch:
    {
      selectedBookshelves(bookshelves, prev)
      {
        // do not sync tags on first load
        if(!_.isNull(prev))
        {
          
          this.isLoading = true

          API.bookshelves.sync(bookshelves, 'book', this.book.id )
                         .then(()=>{
                            
                            this.$store.commit('growl', 'Bookshelves updated')

                            this.isLoading = false
                          
                         })
        }
      }
    }

  }
</script>
