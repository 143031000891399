<template>
  <v-subheader class="grey darken-2 white--text font-weight-bold"
    style="min-width:220px">
    <slot></slot>    
  </v-subheader>
</template>


<script>
  export default {
    name: 'BookContextMenuHeading',

    components: {},

    props: {},

    data: () => ({}),

    mounted(){},

    computed: {},

    methods: {},

    watch: {}

  }
</script>
