<template>
  <div>
    <v-btn small v-if="!datetime"
           @click="openDatepicker">
      <v-icon size="20" class="mr-1" v-if="icon">
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
    <datetime v-model="datetime"
              v-bind="{...options}"
              ref="datepicker"
              :input-class="showDatepicker">
      <span class="description" 
            slot="after"
            v-if="datetime">
        <v-icon @click="openDatepicker">mdi-pencil</v-icon>
      </span>
      <template slot="button-cancel">
        <v-btn class="mr-4">Cancel</v-btn>
        <v-btn @click="datetime = null">Clear</v-btn>
      </template>
      <template slot="button-confirm">
        <v-btn color="success">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save
        </v-btn>
      </template>
    </datetime>
  </div>
</template>

<script>

  import _ from 'lodash'
  import { Datetime } from 'vue-datetime'

  export default {
    name: 'BookDatetime',

    components: {
      Datetime
    },

    props: {
      icon: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: 'Date'
      },
      options: {
        type: Object,
        default: ()=>{}
      },
      value: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      datetime: null,
      datetimePropagation: true
    }),

    mounted(){
      if(this.value)
        this.syncDatetime()
    },

    computed: {
      showDatepicker()
      {
        return this.datetime ? '' : 'd-none'
      }
    },

    methods: {
      openDatepicker(e)
      {
        this.$refs.datepicker.open(e)
      },
      syncDatetime()
      {
        this.datetimePropagation = false
        this.datetime = this.value
      }
    },

    watch: {
      datetime(newValue, oldValue)
      {
        var newDatetime = _.toString(newValue),
            oldDatetime = _.toString(oldValue)

        if( newDatetime != oldDatetime
            && this.datetimePropagation )
          this.$emit('change', newDatetime)

        this.datetimePropagation = true
      },
      value(newValue, oldValue)
      {
        newValue = _.toString(newValue)
        oldValue = _.toString(oldValue)

        if( newValue != oldValue )
          this.syncDatetime()
      }
    }

  }
</script>
