<template>
	<div>
		<v-skeleton-loader
          type="article"
          v-if="loading"
        ></v-skeleton-loader>
		<v-list v-else
				class="py-0"
				:dense="dense">
			<Message v-if="isEmpty">
				No articles here.
			</Message>
			<v-list-item v-else
				v-for="article in theArticles"
				:key="article.id"
				:two-line="withSubtitle"
				@click="onArticleClick(article)">
				<v-list-item-content>
					<v-list-item-title>
						{{ article.title }}
					</v-list-item-title>
					<v-list-item-subtitle v-if="withSubtitle">
						{{ article.owner.title }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>

	import Api from '../../api'

	import Message from '../Message'

	export default {
		name: 'ArticlesList',

		components: {
			Message
		},

		props: {
			articles: {
				type: Array,
				required: false,
				default: ()=> []
			},
			dense: {
				type: Boolean,
				default: false
			},
			modelId: {
				type: Number,
				required: false
			},
			modelType: {
				type: String,
				required: false
			},
			withSubtitle: {
				type: Boolean,
				default: false
			}
		},

		mounted(){
			this.fetchArticles()
		},

		methods: {
			fetchArticles()
			{
				if( this.articles.length )
					this.theArticles = this.articles

				if( !this.modelId || !this.modelType )
					return

				var ws

				switch(this.modelType){
					case 'issue': ws = Api.issues.articles(this.modelId); break;
				}

				if(!ws)
					return

				this.loading = true

				ws.then((res)=>{
					this.theArticles = res.data.data
					this.loading = false
				})
			},
			onArticleClick(article)
			{
				this.$router.push({ name: 'article', params: { articleId: article.id } })
			},
			refresh()
			{
				this.fetchArticles()
			}
		},

		data: () => ({
			theArticles: [],
			loading: false
		}),

		computed: {
			isEmpty()
			{
				return !this.theArticles.length
			}
		},

		watch: {
			articles(articles)
			{
				this.theArticles = articles
			}
		}
	}
</script>