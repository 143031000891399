<template>
  <v-list-item-group v-model="isFav">
    <v-list-item @click="toggleFav" color="warning" :value="true">
      <v-list-item-icon>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            :size="20"
            :width="2"
            color="primary"
          ></v-progress-circular>
          <v-icon v-else-if="isFav">mdi-star</v-icon>
          <v-icon v-else>mdi-star-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            FAVOURITE
          </v-list-item-title>
        </v-list-item-content>       
    </v-list-item>
  </v-list-item-group>
</template>

<script>
  import _ from 'lodash'
  import API from '@/api'
  import { mapGetters } from 'vuex'

  export default {
    name: 'BookContextMenuFavourite',

    components: {},

    props: {
      book: {
        type: Object
      }
    },

    data: () => ({
      isFav: false,
      isLoading: false
    }),

    mounted()
    {
      this.setStatus()
    },

    computed: {
      ...mapGetters([
        'favourites'
      ])
    },

    methods: {
      setStatus()
      {
        let books = this.favourites.books,
            search = { id:this.book.id },
            isFav = _.find(books, search)
        
        this.isFav = isFav ? true : false
      },
      toggleFav()
      {
        this.isLoading = true 

        API.favourites.toggle( "book", this.book.id )
                      .then(()=>{

                        this.$store.commit('growl', 'Favourite toggled')
                        
                        this.$store.dispatch('loadFavourites')

                        this.isLoading = false
                      })
      }
    },

    watch: {
      book()
      {
        this.setStatus()
      }
    }

  }
</script>
