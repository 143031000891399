<template>
  <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :transition="false"
      :close-on-content-click="false"
      class="book-context-menu"
    >
    <!-- main level -->
    <MainList v-if="!submenu" 
              @submenu="openSubmenu"
              :book="book"/>

    <!-- submenu -->
    <component v-else :is="submenu" 
                      @back="closeSubmenu"
                      :book="book" />
  </v-menu>
</template>


<script>
  import MainList from './MainList'

  // Submenus.
  import Bookshelves from './submenu/Bookshelves'
  import Tags from './submenu/Tags'

  export default {
    name: 'BookContextMenu',

    components: {
      MainList,
      Bookshelves,
      Tags
    },

    props: {},

    data: () => ({
      book: null,
      showMenu: false,
      submenu: null,
      x: 0,
      y: 0,
    }),

    mounted(){},

    computed: {},

    methods: {
      closeSubmenu()
      {
        this.submenu = null
      },
      openSubmenu(submenu)
      {
        this.submenu = submenu
      },
      show(e, book)
      {

        this.book = book
        
        e.preventDefault()
        
        this.showMenu = false
        
        this.x = e.clientX
        this.y = e.clientY
        
        this.$nextTick(() => {
          this.showMenu = true
        })

      },
    },

    watch: {
      showMenu(status)
      {
        if(!status)
          this.closeSubmenu()
      }
    }

  }
</script>
