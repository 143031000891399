<template>
  <div class="pr-5 search-wrapper" :class="wrapperCSS">
    <!-- keyboard shortcut -->
    <button class="d-none"
            v-shortkey="['ctrl','f']" 
            @shortkey="focus"></button>
    <!-- search autocomplete -->
    <v-autocomplete ref="input"
          v-model="model"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          color="white"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          :append-icon="liveTypingIcon"
          return-object
          solo-inverted
          flat
          @focus="inputFocus=true"
          @blur="inputFocus=false"
          no-filter
          :autofocus="autofocus"
        >
          <!-- template for search results -->
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <v-icon>{{ data.item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content 
                @click="onSelect(data.item)">
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
    </v-autocomplete>
  </div>
</template>

<style type="text/css">
  .v-text-field__details { display: none; }
  .search-wrapper {
    -webkit-transition: width 1s;
    transition        : width 1s;
    width: auto;
  }
  .search-wrapper.focused.autoexpand { width: 60%; }
</style>

<script>

  import _ from 'lodash'
  import Api from '../api'

  import SearchResult from '@/classes/searchResult'

  import liveTyping from '@/mixins/live-typing'
  import search from '@/mixins/search'

  export default {
    
    name: 'Search',

    mixins: [liveTyping, search],

    components: {},

    props: {
      autofocus: {
        type: Boolean,
        default: false
      },
      autoexpand: {
        type: Boolean,
        default: true
      },
      routing: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      inputFocus: false,
      isLoading: false,
      isTyping: false,
      model: {},
      results: {},
      search: '',
      timer: null
    }),

    mounted(){},

    computed: {
      isInputFocused()
      {
        return this.inputFocus
      },
      wrapperCSS()
      {
        return {
          'focused': this.isInputFocused,
          'autoexpand': this.autoexpand
        }
      },
      items()
      {
        var items = []
          _.each(this.books, (book)=>{ items.push(new SearchResult({ 
            text:book.title, 
            value:book.id, 
            icon:'mdi-book',
            model:'book' 
          })) })
          _.each(this.bookshelves, (bookshelves)=>{ items.push(new SearchResult({ 
            text:bookshelves.name, 
            value:bookshelves.id, 
            icon:'mdi-book-multiple',
            model:'bookshelf' 
          })) })
          _.each(this.genres, (genre)=>{ items.push(new SearchResult({ 
            text:genre.name, 
            value:genre.id, 
            icon:'mdi-asterisk',
            model:'genre' 
          })) })
          _.each(this.quotes, (quote)=>{ items.push(new SearchResult({ 
            text:quote.content, 
            value:quote.owner_id,
            icon:'mdi-bookmark',
            model:'quote' 
          })) })
          _.each(this.authors, (author)=>{ items.push(new SearchResult({ 
            text:author.fullname, 
            value:author.id, 
            icon:'mdi-account-circle',
            model:'author' 
          })) })
          _.each(this.tags, (tag)=>{ items.push(new SearchResult({ 
            text:tag.name, 
            value:tag.id, 
            icon:'mdi-tag',
            model:'tag' 
          })) })
          _.each(this.topics, (topic)=>{ items.push(new SearchResult({ 
            text:topic.name, 
            value:topic.id, 
            icon:'mdi-brain',
            model:'topic' 
          })) })
          
        return items
      },
      authors(){ return ('authors' in this.results) ? this.results.authors : [] },
      books(){ return ('books' in this.results) ? this.results.books : [] },
      bookshelves(){ return ('bookshelves' in this.results) ? this.results.bookshelves : [] },
      genres(){ return ('genres' in this.results) ? this.results.genres : [] },
      quotes(){ return ('quotes' in this.results) ? this.results.quotes : [] },
      tags(){ return ('tags' in this.results) ? this.results.tags : [] },
      topics(){ return ('topics' in this.results) ? this.results.topics : [] }
    },

    methods: {
      debug(what)
      {
        console.log(what)
      },
      focus()
      {
        this.$refs.input.focus()
      },
      onSelect(selected)
      {
        console.log(selected)

        if( this.routing )
          this.routeTo(selected)

        this.$emit('selected', selected)

        this.model = ""
        this.results = []
      },
      routeTo(selected)
      {
        this.openSearchResult(selected)
      }
    },

    watch: {
      search (val) {

        if( !val ) return

        if( !val.replaceAll(' ','').length ) return

        let resolve = () => {

          this.isLoading = true

          // Lazily load input items
          Api.search(val)
            .then(res => {
              this.results = res.data
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))
            
        }

        this.liveType(resolve)

      },
    }

  }
</script>
