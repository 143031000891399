<template>

  <v-dialog
      v-model="dialog"
      width="500"
    >
    <template v-slot:activator="{ on }">
      <v-btn
        color="secondary" 
        :class="buttonClass"
        dark
        v-on="on"
        v-if="fab"
        fab 
      >
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-btn>
      <v-btn
        color="secondary" 
        :class="buttonClass"
        dark
        v-on="on"
        v-else
      >
        {{ label }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="bookId">Edit book</span>
        <span v-else>Add a book</span>
      </v-card-title>

      <v-card-text>

        <v-form>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field
                  label="Title"
                  v-model="book.title"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-combobox
                  v-model="book.authors"
                  :loading="authorsLoading"
                  :items="authors" 
                  item-text="fullname"
                  item-value="id"
                  label="Authors" 
                  :search-input.sync="authorsSearch"
                  multiple 
                  clearable 
                  chips
                  deletable-chips
                  @change="onAuthorsChange"
                ></v-combobox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="ISBN"
                  v-model="book.isbn"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Year"
                  v-model="book.year" 
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Position"
                  v-model="book.position"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  import _ from 'lodash'
  import Utils from '../../utils'

  export default {
    name: 'BookModal',

    mixins: [
      Utils
    ],

    components: {},

    props: {
      bookId: {
        type: Number
      },
      buttonClass: {
        type: String
      },
      fab: {
        type: Boolean,
        default: ()=> false
      },
      icon: {
        type: String,
        default: ()=> 'mdi-pencil'
      },
      label: {
        type: String,
        default: ()=> 'New'
      }
    },

    data: () => ({
      authors: [],
      authorsLoading: false,
      authorsSearch: "",
      book: {},
      dialog: false,
      overlay: false
    }),

    mounted(){
      if( this.bookId )
        this.fetchBook()
    },

    methods: {
      fetchAuthors( search = "" )
      {
        Api.authors.list({ s:search }).then((res)=>{
            this.authors = _.orderBy(res.data.data, 'fullname')
          })
      },
      fetchBook()
      {
        if(this.bookId)
          Api.books.one(this.bookId).then((res)=>{
            this.book = res.data.data
          })
      },
      onAuthorsChange()
      {
        this.authorsSearch = ""
      },
      removeAuthor(author)
      {
        console.log(author)
      },
      save()
      {
        if(this.bookId){
          
          this.overlay = true
          
          Api.books.update(this.bookId, this.book)
            .then((res)=>{
              
              let bookId = res.data.data.id

              Api.books.updateAuthors(bookId, this.book)
                  .then(()=>{
                    this.overlay = false
                    this.dialog = false
                    this.$emit('save', this.book)
                  })
                  .catch((e)=>{
                    this.overlay = false
                    this.$store.commit('growl', {
                      color: 'error',
                      text: e.response.data.message
                    })
                  })

            })
            .catch((e)=>{
              this.overlay = false
              this.$store.commit('growl', {
                color: 'error',
                text: e.response.data.message
              })
            })

        }
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      authorsSearch (search) {
        if(search)
          this.fetchAuthors(search)
        else
          this.authors = []
      },
      bookId(newValue)
      {
        if(newValue)
          this.fetchBook()
      }
    },

  }
</script>
