<template>

  <v-dialog
      v-model="dialog"
      :fullscreen="$mq=='sm'">

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="isUpdate">Edit Article</span>
        <span v-else>Add an Article</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  v-model="theArticle.title"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Import PDF from URL"
                  v-model="importPdfUrl"
                  v-on:keyup.enter="save"
                ></v-text-field>
                <Message v-if="checkPdf"
                         color="warning">
                  <v-icon color="warning">mdi-alert</v-icon>
                  This article already <a :href="article.pdf" target="_blank" class="warning--text"><strong>has a PDF</strong></a> and you are going to override it!
                </Message>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="URL"
                  v-model="theArticle.url"
                  v-on:keyup.enter="save"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Description</span>
                <note-editor :content.sync="theArticle.description" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" 
               @click="save">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
      </v-card-actions>

    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import Api from '../../api'

  import Message from '../Message'
  import NoteEditor from '../note/Editor'

  export default {
    name: 'ArticleModal',

    components: {
      Message,
      NoteEditor
    },

    props: {
      articleId: {
        type: Number
      },
      article: {
        type: Object,
        default: ()=> { return {} }
      },
      ownerId: {
        type: Number,
        required: false
      },
      ownerType: {
        type: String,
        required: false
      }
    },

    data: () => ({
      dialog: false,
      importPdfUrl: '',
      overlay: false,
      theArticle: {},
    }),

    computed: {
      checkPdf()
      {
        return ( this.importPdfUrl 
                 && this.article.pdf 
                 && this.article.id )
      },
      isUpdate()
      {
        return this.theArticle.id
      }
    },

    mounted(){
      if(this.article)
        this.theArticle = this.article
    },

    methods: {
      save()
      {
        this.overlay = true

        var article = this.theArticle
          
        if(this.ownerId)  
          article.owner_id = this.ownerId

        if(this.ownerType)
          article.owner_type = this.ownerType

        if(this.importPdfUrl)
          article.pdf = this.importPdfUrl

        var ws = article.id ? 
                 Api.articles.update(article.id, article) : 
                 Api.articles.create(article)
        
        ws.then((res)=>{
            
            this.overlay = false
            this.dialog = false
            this.$emit('save', res.data.data)

          })
          .catch((e)=>{
            this.overlay = false
            this.$store.commit('growl', {
              color: 'error',
              text: e.response.data.message
            })
          })

      },
      reset()
      {
        //this.theArticle = {}
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      dialog(isOpening)
      {
        if( !isOpening )
          this.reset()
      },
      article(article)
      {
        if(article)
          this.theArticle = article
      },
      articleId(articleId)
      {
        if(articleId)
          Api.articles.one(articleId)
                       .then((res)=>{
                        this.theArticle = res.data.data
                       })
      }
    },

  }
</script>
