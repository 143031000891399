<template>
  <div>
    <v-tabs v-model="tab" 
            background-color="grey"
            center-active
            dark>
      <v-tab key="quotes">
        <v-icon class="mr-3">mdi-bookmark</v-icon>
        Quotes 
        <v-chip class="ml-3">
          {{ book.quotes_count }}
        </v-chip>
      </v-tab>
      <v-tab key="notes">
        <v-icon class="mr-3">mdi-note-multiple</v-icon>
        Notes
        <v-chip class="ml-3">
          {{ book.notes_count }}
        </v-chip>
      </v-tab>
      <v-tab key="topics">
        <v-icon class="mr-3">mdi-brain</v-icon>
        Topics
        <v-chip class="ml-3">
          {{ book.topics_extended_count }}
        </v-chip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" 
                  class="grey lighten-3 pb-15"
                  style="overflow:hidden;">
      <v-tab-item key="quotes">
        <Quotes ref="quotes" 
                class="mt-5" 
                :model-id="book.id"
                model-type="book"
                @delete="onQuoteDelete"
                v-if="book.id" />
      </v-tab-item>
      <v-tab-item key="notes">
        <Notes ref="notes" 
               class="mt-5" 
               model-type="book"
               :model-id="book.id"
               @delete="onNoteDelete" />
      </v-tab-item>
      <v-tab-item key="topics">
        <Topics :model-id="book.id" model-type="book" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import Notes from '../note/Notes'
  import Quotes from '../quote/Quotes'
  import Topics from '../topic/Topics'

  export default {
    name: 'BookTabs',

    components: {
      Notes,
      Quotes,
      Topics
    },

    props: {
      book: {
        type: Object
      }
    },

    data: () => ({
      tab: 'quotes'
    }),

    mounted(){},

    methods: {
      fetchNotes()
      {
        this.$refs.notes.fetchNotes()
      },
      fetchQuotes()
      {
        this.$refs.quotes.fetchQuotes()
      },
      onNoteDelete()
      {
        this.book.notes_count--
      },
      onQuoteDelete()
      {
        this.book.quotes_count--
      }
    }

  }
</script>
